import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/graphics/15.svg'


const _hoisted_1 = { id: "pattern" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"wrap\" data-v-e14018ea><img id=\"p1\" class=\"pattern\" src=\"" + _imports_0 + "\" width=\"35\" height=\"35\" data-v-e14018ea><img id=\"p2\" class=\"pattern\" src=\"" + _imports_0 + "\" width=\"35\" height=\"35\" data-v-e14018ea><img id=\"p3\" class=\"pattern\" src=\"" + _imports_0 + "\" width=\"35\" height=\"35\" data-v-e14018ea><img id=\"p4\" class=\"pattern\" src=\"" + _imports_0 + "\" width=\"35\" height=\"35\" data-v-e14018ea><img id=\"p5\" class=\"pattern\" src=\"" + _imports_0 + "\" width=\"35\" height=\"35\" data-v-e14018ea></div>", 1)
  ])))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createElementBlock("div", {
        key: link.id
      }, [
        (_openBlock(), _createElementBlock("a", {
          key: link.id,
          target: "_blank",
          href: link.link
        }, _toDisplayString(link.name), 9, _hoisted_2))
      ]))
    }), 128))
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoBlock = _resolveComponent("InfoBlock")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Pattern = _resolveComponent("Pattern")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infos, (info) => {
        return (_openBlock(), _createElementBlock("li", {
          key: info.id
        }, [
          _createVNode(_component_InfoBlock, { info: info }, null, 8, ["info"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_Footer),
    (_ctx.rendered)
      ? (_openBlock(), _createBlock(_component_Pattern, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}